import React from 'react';
import PublicRoute from './services/auth/PublicRoute';

// Login and Register pages
const ChooseLogin = React.lazy(() => import('./views/pages/login/ChooseLogin'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
const AdminLogin = React.lazy(() => import('./views/pages/login/AdminLogin'));
const Register = React.lazy(() => import('./views/pages/register/Register'));

// Forget password and Reset password pages
const CheckEmail = React.lazy(() => import('./views/pages/forgetPassword/CheckEmail'));
const CreatePassword = React.lazy(() => import('./views/pages/forgetPassword/CreatePassword'));
const PasswordReset = React.lazy(() => import('./views/pages/forgetPassword/PasswordReset'));

const SetupWelcome = React.lazy(() => import('./views/pages/setepForm/SetupWelcome'));
const UserDashboard = React.lazy(() => import('./views/pages/dashboard/UserDashboard'));
const AdminDashboard = React.lazy(() => import('./views/pages/dashboard/AdminDashboard'));
const SettingPage = React.lazy(() => import('./views/pages/settings/settings'));

const routes = [
    // Login and Register path
    { path: '/', name: 'Login', element: <PublicRoute><Login /></PublicRoute> },
    { path: '/register', name: 'Register', element: <PublicRoute><Register /></PublicRoute> },

    // Forget password and Reset password path
    { path: '/check-email', name: 'CheckEmail', element: <PublicRoute><CheckEmail /></PublicRoute> },
    { path: '/create-password', name: 'CreatePassword', element: <PublicRoute><CreatePassword /></PublicRoute> },
    { path: '/password-reset', name: 'PasswordReset', element: <PublicRoute><PasswordReset /></PublicRoute> },

    { path: '/welcome', name: 'SetupWelcome', element: <SetupWelcome /> },
    { path: '/user-dashboard', name: 'UserDashboard', element: <UserDashboard /> },
    { path: '/admin-dashboard', name: 'AdminDashboard', element: <AdminDashboard /> },
    { path: '/settings', name: 'SettingsPage', element: <SettingPage /> },
];

export default routes;