import React from "react";
import { motion } from "framer-motion";

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(85,139,47, 0)",
  },
  visible: {
    opacity: 1,
    pathLength: 1,
  },
};

const Loading = () => (
  <div className="loading-body">
    <div className="loading-container">
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        className="item"
      >
        <motion.path
          d="M102.563 114.77C102.563 114.77 114.512 103.319 129.449 101.825C144.385 100.332 161.811 107.044 161.811 107.044M113.019 125.964C113.019 125.964 124.492 116.395 135.921 115.268C147.351 114.142 160.317 116.006 160.317 116.006M119.491 140.9C119.491 140.9 135.423 131.201 145.381 128.213C155.338 125.226 157.828 126.461 157.828 126.461M37 105.5C37 105.5 61.2392 101.803 72.6904 102.798C84.1416 103.794 93.6013 109.769 93.6013 109.769L101.567 115.743C101.567 115.743 111.525 124.968 113.517 127.692C115.508 130.417 118.993 137.152 119.989 143.624C120.985 150.097 121.5 159 121.5 159M39.3325 117.5C39.3325 117.5 53.9531 116.147 63.7286 116.922C73.504 117.697 81.1544 122.216 81.1544 122.216C81.1544 122.216 88.6226 126.461 89.6183 127.692C90.6141 128.924 99.078 136.917 101.07 139.642C103.061 142.366 104.057 144.122 105.55 149.101C107.044 154.08 108.538 161.811 108.538 161.811M42.3198 128.19C42.3198 128.19 50.2858 128.19 57.754 130.181C65.2222 132.173 70.201 134.663 70.201 134.663L77.6692 140.637L86.1332 149.101C86.1332 149.101 88.9823 151.384 90.1162 155.076C91.25 158.767 92.1077 162.544 92.1077 162.544M99.5759 114.512V90.9819M99.5759 90.9819V79.739M99.5759 90.9819C99.5759 90.9819 81.9488 89.8874 79.1946 83.868C76.4404 77.8486 73.6862 64.1681 73.6862 64.1681C73.6862 64.1681 81.9488 64.1681 88.0081 67.4515C92.2828 69.7677 97.1057 76.1691 99.5759 79.739M99.5759 79.739V73.4709C99.5759 73.4709 101.779 64.1681 106.737 60.8848C111.694 57.6015 125.466 53.771 125.466 53.771L123.813 65.8098C123.813 65.8098 123.813 71.8292 118.855 75.6597C113.898 79.4903 101.228 82.2264 101.228 82.2264C101.228 82.2264 100.607 81.2295 99.5759 79.739ZM164.3 99.5759C164.3 135.322 135.322 164.3 99.5759 164.3C63.8296 164.3 34.8516 135.322 34.8516 99.5759C34.8516 63.8296 63.8296 34.8516 99.5759 34.8516C135.322 34.8516 164.3 63.8296 164.3 99.5759Z"
          variants={icon}
          initial="hidden"
          animate="visible"
          transition={{
            default: { duration: 5, ease: "easeInOut" },
            fill: { duration: 5, ease: "easeInOut" },
          }}
        />
      </motion.svg>
    </div>
  </div>
);

export default Loading;
